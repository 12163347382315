/* eslint-disable quote-props */
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { TicketCategory, TicketStatus } from 'src/app/models/Ticket';

export type ChipStyle = {
  background: string;
  color: string;
  border: string;
};

@Component({
  selector: 'app-ticket-chip',
  templateUrl: './ticket-chip.component.html',
  styleUrls: ['./ticket-chip.component.scss']
})
export class TicketChipComponent implements OnInit, OnChanges {
  @Input({ required: true }) label!: TicketCategory | TicketStatus;

  style!: ChipStyle;

  ngOnInit(): void {
    this.generateStyle();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.generateStyle();
  }

  generateStyle() {
    this.style = {
      background: this.isLabelTicketCategory()
        ? this.backgroundColor
        : 'transparent',
      color: this.isLabelTicketCategory() ? '#fff' : this.backgroundColor,
      border: this.isLabelTicketCategory()
        ? 'none'
        : `1px solid ${this.backgroundColor}`
    };
  }

  get backgroundColor(): string {
    switch (this.label) {
      case TicketCategory.Commissioning:
        return '#60B254';
      case TicketCategory.Emergency:
        return '#C26262';
      case TicketCategory.Maintenance:
        return '#9B5FC4';
      case TicketCategory.SGC:
        return '#f264f7';
      case TicketCategory.Malfunction:
        return '#C2A74D';
      case TicketCategory.Task:
        return '#658DC8';
      case TicketStatus.Cancelled:
        return '#f44336';
      case TicketStatus.Completed:
        return '#32CD32';
      case TicketStatus.Planning:
        return '#45a8c6';
      case TicketStatus.InProgress:
        return '#FFBB00';
      case TicketStatus.Complaint:
        return '#9412AB';
      case TicketStatus.Complained:
        return '#A06CE9';
      case TicketStatus.Goodwill:
        return '#1779C2';
      case TicketStatus.Settle:
        return '#004d00';
      case TicketStatus.Settled:
        return '#008000';
      case TicketStatus.Done:
        return '#ADFF2F';
      case TicketStatus.Paused:
        return '#BE86A3';
      case TicketStatus.Open:
        return '#0090FF';
      default:
        return '#000';
    }
  }

  get icon(): string {
    switch (this.label) {
      case TicketCategory.Commissioning:
        return 'pi pi-wrench';
      case TicketCategory.Emergency:
        return 'pi pi-times';
      case TicketCategory.Maintenance:
        return 'pi pi-cog';
      case TicketCategory.SGC:
        return 'pi pi-cog';
      case TicketCategory.Malfunction:
        return 'pi pi-exclamation-triangle';
      case TicketCategory.Task:
        return 'pi pi-check-square';
      default:
        return '';
    }
  }

  isLabelTicketCategory(): boolean {
    return Object.values(TicketCategory).includes(this.label as TicketCategory);
  }
}
