import {
  ExtractCreateModel,
  ExtractUpdateModel
} from 'src/app/utils/types/extract-action-model';
import { Appointment } from './Appointment';
import { Customer } from './customer/Customer';
import { CustomerContactPerson } from './customer/CustomerContactPerson';
import { CustomerDevice } from './customer/CustomerDevice';
import { FileDto } from './FileDto';
import { HiveForm } from './HiveForm';
import { ReoccurringInterval } from './ReoccurringInterval';
import { User } from './User';

export const TicketCategory = {
  Commissioning: 'Commissioning',
  Emergency: 'Emergency',
  Malfunction: 'Malfunction',
  Task: 'Task',
  Maintenance: 'Maintenance',
  SGC: 'SGC'
} as const;

export type TicketCategory =
  (typeof TicketCategory)[keyof typeof TicketCategory];

export const TicketStatus = {
  Planning: 'Planning',
  Open: 'Open',
  InProgress: 'InProgress',
  Paused: 'Paused',
  Cancelled: 'Cancelled',
  Done: 'Done',
  Goodwill: 'Goodwill',
  Complaint: 'Complaint',
  Complained: 'Complained',
  Settle: 'Settle',
  Settled: 'Settled',
  Completed: 'Completed'
} as const;

export type TicketStatus = (typeof TicketStatus)[keyof typeof TicketStatus];

export const ClientType = {
  Craftsman: 'Craftsman',
  EndCustomer: 'EndCustomer',
  NothaftHeiztechnik: 'NothaftHeiztechnik',
  Unknown: 'Unknown'
} as const;

export type ClientType = (typeof ClientType)[keyof typeof ClientType];

export type Ticket = {
  readonly id: number;

  ticketNumber: string;

  subject: string;

  description: string;

  requestReason: string;

  files: FileDto[] | File[];

  customerDocumentIds?: number[];

  ticketCategoryType: TicketCategory;

  ticketStatusType: TicketStatus;

  customer: Customer | null;

  customerId?: number;

  customerContactPerson?: CustomerContactPerson | null;

  customerContactPersonId?: number;

  customerDevices?: CustomerDevice[];

  editorId?: number;

  editor?: User;

  editedById?: number;

  reoccurringInterval?: ReoccurringInterval;

  executionDate?: Date;

  nextExecutionDate?: Date;

  appointments?: Appointment[];

  isAppointmentNeeded: boolean;

  forms?: HiveForm[];

  clientType: ClientType;

  readonly createdAt: Date;

  readonly createdById: number;

  createdBy: User;

  readonly updatedAt?: Date;

  readonly updatedById?: number;

  updatedBy?: User;

  readonly deletedAt?: Date | null;

  readonly deletedById?: number | null;

  deletedBy?: User;
};

export type TicketCreate = Omit<
  ExtractCreateModel<Ticket>,
  'createdBy' | 'ticketNumber' | 'editorId' | 'customerId'
>;

export type TicketUpdate = Omit<
  ExtractUpdateModel<Ticket>,
  'createdBy' | 'ticketNumber' | 'editorId' | 'customerId'
>;
